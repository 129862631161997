import { render, staticRenderFns } from "./PageGuestLanding.vue?vue&type=template&id=1850f642&"
import script from "./PageGuestLanding.vue?vue&type=script&lang=js&"
export * from "./PageGuestLanding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAvatar,VCard,VCol,VContainer,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VRow,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tp-vision-web1-vuetify2/tp-vision-web1-vuetify2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1850f642')) {
      api.createRecord('1850f642', component.options)
    } else {
      api.reload('1850f642', component.options)
    }
    module.hot.accept("./PageGuestLanding.vue?vue&type=template&id=1850f642&", function () {
      api.rerender('1850f642', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/guest/PageGuestLanding.vue"
export default component.exports