var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "div",
                { staticClass: "text-center mb-3" },
                [
                  _c("v-avatar", { attrs: { size: "150" } }, [
                    _c("img", {
                      attrs: { alt: "user", src: "/images/Icon.png" },
                    }),
                  ]),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mx-auto mb-3", attrs: { "max-width": "500" } },
                [
                  _c(
                    "v-list",
                    [
                      _c("v-subheader", [_vm._v("Choose the language")]),
                      _c(
                        "v-list-item-group",
                        _vm._l(_vm.locales, function (item, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.changeLocale(item)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s(item.name),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }